import React from "react";
import {Button} from "@nextui-org/react";

export default function Content() {
    const handleChromeExtensionButtonClick = () => {
        window.open('https://chromewebstore.google.com/detail/privacy-protector/ildkmmifmkofbdnneddkdhmannhoeoih', '_blank');
    };
    return (
        <div>
            <div className='flex justify-center'>
                <a href="https://www.producthunt.com/posts/privacy-protector?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-privacy&#0045;protector">
                    <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=476921&theme=light"
                        alt="Privacy&#0032;Protector - Expose&#0032;What&#0032;Sites&#0032;Know&#0032;About&#0032;You"
                        />
                </a>
            </div>

            <p className="flex justify-center text-5xl font-bold leading-[72px]">
                Expose What Sites Know About You
            </p>

            <div
                className='flex justify-center mt-6'>
                <Button color='secondary' radius="full" startContent={<img
                    src="https://www.google.com/chrome/static/images/chrome-logo.svg" alt="Chrome Logo"
                    style={{
                        width: '30px', height: '30px', marginRight: '10px',
                    }}/>} style={{
                    fontSize: '20px', width: '240px', height: '60px',
                }} onClick={handleChromeExtensionButtonClick}>
                    Add To Chrome
                </Button>
            </div>

            <div className='flex justify-center items-center mt-[20px]'>
                <iframe
                    src={`https://www.youtube.com/embed/AUcwBnSoPy0`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Privacy Protector Sample Video"
                    className='h-[460px] w-[800px] mb-8'
                />
            </div>
        </div>
    );
}