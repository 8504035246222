
function Footer() {

    return (
        <div className="flex self-start m-8">
            <div className="flex flex-col w-1/4">
                <div className="flex gap-3 text-lg font-semibold leading-7 text-slate-950">
                    <img loading="lazy"
                         src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9fa2339b9e995c27a64d6abb810191defa9a35dd98676c24f0d2f1e211c7cd2?apiKey=b4ac46710a8549ebbc27632d6ed766ca&"
                         alt="Privacy Protector Logo" className="shrink-0 w-10 aspect-square"/>
                    <div className="flex-auto my-auto">Privacy Protector</div>
                </div>
                <p className="mt-5 text-sm text-slate-500">© 2024 Privacy Protector. All rights reserved.</p>
            </div>
            <div className="text-slate-500 flex ml-20">
                <a href="/privacy_policy" className='flex-1 self-center'>Privacy Policy</a>
                {/*<a className="flex-1 self-center" >Terms of service</a>*/}
                {/*<a className="flex-1 self-center">Contact</a>*/}
            </div>
        </div>
    )

}

export default Footer;