import * as React from "react";

function FeatureCard({imgSrc, altText, title, description}) {
    return (
        <article
            className="flex flex-col grow px-4 pt-4 w-1/4 text-base rounded-xl border">
            <div className="flex gap-2 font-bold leading-[149%]">
                <img loading="lazy" src={imgSrc} alt={altText}
                     className="shrink-0 self-start w-5 border-2 border-indigo-500 border-solid aspect-[1.18] stroke-[2px] stroke-indigo-500"/>
                <h3 className="flex-auto">{title}</h3>
            </div>
            <p className="mt-3.5 leading-6 mb-[10px]">{description}</p>
        </article>
    );
}

function Features() {
    const features = [
        {
            imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/3e270b3d7f966d60997061ccedc47ee655c1a1ec43744c6eb96d6b35d7fff490?apiKey=b4ac46710a8549ebbc27632d6ed766ca&",
            altText: "Data Collection and Storage Icon",
            title: "Data Collection and Storage",
            description: "Checks if unnecessary data is collected and evaluates the duration and security of its storage",
        },
        {
            imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a63833f372ec76d7907c55c0a89f5c5a3940250b36bfa29e6a0e92fae44fc2e3?apiKey=b4ac46710a8549ebbc27632d6ed766ca&",
            altText: "Data Usage and Sharing Icon",
            title: "Data Usage and Sharing",
            description: "Analyzes if data is used for advertising or marketing and checks if it is shared with third parties",
        },
        {
            imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a63833f372ec76d7907c55c0a89f5c5a3940250b36bfa29e6a0e92fae44fc2e3?apiKey=b4ac46710a8549ebbc27632d6ed766ca&",
            altText: "User Control and Rights Icon",
            title: "User Control and Rights",
            description: "Assesses user access and control over data; ensures users can manage account information and withdraw consent",
        },
        {
            imgSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/a63833f372ec76d7907c55c0a89f5c5a3940250b36bfa29e6a0e92fae44fc2e3?apiKey=b4ac46710a8549ebbc27632d6ed766ca&",
            altText: "Transparency Icon",
            title: "Transparency",
            description: "Ensures the privacy policy is clear, understandable, publicly accessible, and easy to read",
        },
    ];

    return (
        <div
            className="flex flex-col px-5 ml-[100px] mr-[100px] mt-[50px] mb-[50px]">
            <h1 className="flex justify-center text-5xl font-bold leading-10 text-slate-950 max-md:text-4xl"> Features </h1>
            {/*<p className="flex justify-center mt-6 text-lg leading-7 text-slate-950 max-md:max-w-full"> "Privacy Protector: Master*/}
            {/*    Expose What Sites Know About You" </p>*/}
            <div className="flex justify-center mt-10 gap-10">
                {features.map((feature) => (
                    <FeatureCard key={feature.title}
                                 imgSrc={feature.imgSrc}
                                 altText={feature.altText}
                                 title={feature.title}
                                 description={feature.description}/>
                ))}
            </div>
            <div className="flex mt-8">
                <div className='w-1/2'>
                    <img
                        src="https://cdn.pixabay.com/photo/2016/11/07/14/03/japan-1805865_640.jpg"
                        alt="Illustration of process"
                        className="flex justify-start rounded-2xl h-[470px] w-full"/>
                </div>
                <div className="flex flex-col w-1/2 ml-20">
                    <div className="flex flex-col text-4xl mb-7">
                        <p className="font-bold">Fast scan</p>
                        <article className="mt-3 text-base leading-6 max-md:max-w-full">
                           <span> As soon as you open a website, our system scans its privacy terms and conditions </span>
                            <span className="font-bold"> in 5s</span>.
                        </article>
                    </div>
                    <div className="flex flex-col text-4xl mb-7">
                        <p className="font-bold">Advanced AI combo</p>
                        <article className="mt-6 text-base leading-6 max-md:max-w-full">
                            <p>
                                <span className="font-bold">GPT4o + Pro SFT </span>can simultaneously
                                <span className="font-bold"> verify </span>privacy terms content against relevant
                                website screenshots.
                            </p>
                        </article>
                    </div>
                    <div className="flex flex-col text-4xl mb-7">
                        <p className="font-bold">Comprehensive Database</p>
                        <article className="flex mt-5 text-base leading-6 max-md:max-w-full">
                            <p>
                                <span className="font-bold">Double-Checked </span>with a Robust Data Validation
                                Database.
                            </p>
                        </article>
                    </div>
                    <div className="flex flex-col text-4xl mb-7">
                        <p className="font-bold">Detailed Explanations</p>
                        <article className="mt-6 text-base leading-6 max-md:max-w-full">
                            <p>
                                <span> For each flagged issue, you receive </span>
                                <span className="font-bold">clear explanation</span>
                                <span> of why it's problematic, how it could impact you, and what rights are being violated.</span>
                            </p>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Features;