import React, {useEffect, useState} from "react";
import {
    Avatar,
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem
} from "@nextui-org/react";
import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom';

function Header() {
    const [loginButtonDisplay, setLoginButtonDisplay] = useState('flex');
    const [userIconDisplay, setUserIconDisplay] = useState('none');
    const [userPicture, setUserPicture] = useState('');

    const handlePricingClick = () => {
        const target = document.getElementById('pricing-div');
        window.scrollTo({
            top: target.offsetTop,
            behavior: 'smooth',
        });
    };


    useEffect(() => {
        setUser()
    }, []);

    const setUser = () => {
        const userString = Cookies.get('privacyProtectorUser');
        if (userString) {
            try {
                const user = JSON.parse(userString);
                setUserPicture(user.picture)
                setLoginButtonDisplay('none')
                setUserIconDisplay('flex')
            } catch (error) {
                console.error('Error parsing privacy protector user data from cookies:', error);
            }
        }
    };

    const unSetUser = () => {
        setUserPicture('')
        setLoginButtonDisplay('flex')
        setUserIconDisplay('none')
    }


    let navigate = useNavigate();
    const handleLoginClick = () => {
        navigate('/login')
    }
    const handleLogoutClick = () => {
        Cookies.remove('privacyProtectorUser', {path: '/', secure: true, sameSite: 'Strict'});
        unSetUser()
    }

    return (
        <Navbar style={{
            marginTop: '32px', marginBottom: '80px'
        }}>
            <NavbarBrand>
                <img
                    src="logo_low.png"
                    alt="privacy protector logo"
                    className='w-[30px] h-[30px] cursor-pointer'/>
                <p style={{
                    marginLeft: "12px",
                    fontWeight: "bold",
                    fontSize: '20px',
                    opacity: 0.75
                }}>Privacy Protector</p>
            </NavbarBrand>
            <NavbarContent justify="center">
                <NavbarItem>
                    <Button
                        variant="light"
                        className='text-xl
                        text-zinc-600'
                        onClick={handlePricingClick}>
                        Pricing
                    </Button>
                </NavbarItem>
            </NavbarContent>
            <NavbarContent justify="end">
                <NavbarItem>
                    <NavbarItem>
                        <Button
                            color="primary"
                            variant="light"
                            style={{
                                fontSize: "18px",
                                display: loginButtonDisplay
                            }}
                            onClick={handleLoginClick}
                        >
                            Login
                        </Button>

                        <Dropdown>
                            <DropdownTrigger>
                                <Avatar
                                    as="button"
                                    className="transition-transform"
                                    src={userPicture}
                                    style={{
                                        display: userIconDisplay
                                    }}
                                />
                            </DropdownTrigger>
                            <DropdownMenu aria-label="Profile Actions" variant="flat" className='h-13 flex'>
                                <DropdownItem key="profile" onClick={handleLogoutClick}>
                                    <p className="font-semibold flex justify-center">Logout</p>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </NavbarItem>
                </NavbarItem>
            </NavbarContent>
        </Navbar>

    );
}

export default Header;