import * as React from "react";
import {Card, CardBody, CardHeader, Button} from "@nextui-org/react";


function Pricing() {
    const handleYearlyPricingClick = () => {
        window.location.href = 'https://buy.stripe.com/5kA6oC7Rl0iK15C3cc'
    }

    const handleMonthlyPricingClick = () => {
        window.location.href = 'https://buy.stripe.com/4gw5kydbFd5w9C8aEH'
    }

    return (
        <div className='h-[500px] mb-5' id='pricing-div'>
            <h2 className="flex justify-center mt-10 text-5xl font-bold">Pricing</h2>
            <p className="flex justify-center mt-4 text-lg" style={{
                opacity: .65
            }}>Choose the plan that works best for you.</p>

            <div className="flex justify-center mt-10 gap-10">
                <Card className="w-[350px]">
                    <CardHeader className='flex h-[50px] bg-sky-500 justify-between items-center'>
                        <p className="text-2xl font-bold text-white">Free</p>
                    </CardHeader>
                    <CardBody>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold text-amber-500">Free Plan</p>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold ">$0.00/month</p>
                        <p className="text-medium self-center">10 Free Notifications</p>
                        <Button isDisabled color='primary' className='mt-10 mb-3 text-xl'>Get Started</Button>
                    </CardBody>
                </Card>

                <Card className="w-[350px]">
                    <CardHeader className='flex h-[50px] bg-sky-500 justify-between items-center'>
                        <p className="text-2xl font-bold text-white">Most Popular</p>
                        <p className="text-white border border-white rounded pl-2 pr-2">Save 80%</p>
                    </CardHeader>
                    <CardBody>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold text-amber-500">Yearly</p>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold ">$1.66/month</p>
                        <p className="text-medium self-center">($19.9/year)</p>
                        <Button onClick={handleYearlyPricingClick} color='primary' className='mt-10 mb-3 text-xl'> Get
                            Premium</Button>
                    </CardBody>
                </Card>

                <Card className="w-[350px]">
                    <CardHeader className='flex h-[50px] bg-sky-500 justify-between items-center'>
                        <p className="text-2xl font-bold text-white">Regular</p>
                    </CardHeader>
                    <CardBody>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold text-amber-500">Monthly</p>
                        <p className="text-4xl mt-5 mb-2 self-center font-bold ">$2.99/month</p>
                        <p className="text-medium self-center">($35.9/year)</p>
                        <Button onClick={handleMonthlyPricingClick} color='primary' className='mt-10 mb-3 text-xl'> Get
                            Premium</Button>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default Pricing;