import React from 'react';
import './index.css';
import Header from './header';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './login';
import PrivacyPolicy from "./privacy_policy";
import Content from './content';
import Features from "./features";
import Pricing from './pricing';
import Footer from './footer';
import {Divider} from "@nextui-org/react";


function Home() {


    return (
        <div className="App">
            <Header/>
            <Content/>
            <Divider className="my-4"/>
            <Features/>
            <Divider className="my-4"/>
            <Pricing/>
            <Divider className="my-4"/>
            <Footer/>
        </div>
    );
}

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
            </Routes>
        </Router>
    )
}

export default App;