import React from "react";
import {Card, CardBody, CardHeader} from "@nextui-org/react";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import './index.css';
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import {useNavigate} from "react-router-dom";


export default function Login() {
    const navigate = useNavigate();

    const globals = require('./globals');

    const onLoginSuccess = (response) => {
        const user = jwtDecode(response.credential);
        const userString = JSON.stringify(user);
        Cookies.set('privacyProtectorUser', userString, {expires: 7});

        fetch(`${globals.serverUrl}/privacy_protector/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({name: user.name, email: user.email, picture: user.picture}),
        }).catch(error => {
            console.error('when send user to server in login met error: ', error);
        });

        navigate('/');
    };

    const onLogoClick = () => {
        navigate('/')
    }


    return (
        <div className='w-full h-screen flex justify-center items-center'>
            <div className='flex-col w-[360px] h-[200px]'>
                <div className='flex justify-start w-full h-[50px]'>
                    <img
                        src="logo_low.png"
                        alt="privacy protector logo"
                        className='w-[30px] h-[30px] cursor-pointer'
                        onClick={onLogoClick}
                        />
                    <p style={{
                        marginLeft: "12px",
                        fontWeight: "bold",
                        fontSize: '20px',
                        opacity: 0.75
                    }}>Privacy Protector</p>
                </div>
                <Card className="p-3 w-full">
                    <CardHeader className='flex-col'>
                        <h1 className="text-4xl font-extrabold">Welcome back</h1>
                        <p className="mt-3 text-large" style={{color: 'rgb(100, 116, 139)'}}>
                            Please login with Google account
                        </p>
                    </CardHeader>
                    <CardBody className='w-full'>
                        <GoogleOAuthProvider
                            clientId="732320343540-acl8hfbmhqv6msn2tv2ke8u0gesohkh5.apps.googleusercontent.com">
                            <GoogleLogin
                                shape='circle'
                                className='self-center'
                                onSuccess={onLoginSuccess}
                            />
                        </GoogleOAuthProvider>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}


