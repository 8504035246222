import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

            <p className="mb-2">
                At Privacy Protector, we prioritize your privacy and are committed to
                handling your personal data responsibly. This Privacy Policy explains how
                we collect, use, and protect your information when you use our Privacy
                Protector extension.
            </p>

            <h2 className="text-2xl font-bold mb-2">Data Collection and Use</h2>

            <p className="mb-2">
                Our main objective is to help users quickly understand the privacy
                policies of various websites and provide key insights. Here’s how we
                handle your data:
            </p>

            <h3 className="text-xl font-bold mb-2">Functionality and Purpose</h3>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>Risk Assessment:</b> We analyze the privacy policies of websites
                    to determine if they collect a significant amount of personal data,
                    providing a risk rating of high, medium, or low.
                </li>
                <li>
                    <b>Summary and Details:</b> We summarize key points and provide
                    specific details on what types of information a website collects.
                </li>
            </ul>

            <h3 className="text-xl font-bold mb-2">Data We Collect</h3>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>While using our products (automatically, except for Issue Reporter)</b>
                    <ul className="list-disc list-inside ml-6">
                        <li>
                            <b>Extension updates, subscription downloads, emergency notifications:</b>
                            <ul className="list-disc list-inside ml-6">
                                <li>Browser version</li>
                                <li>Extension version</li>
                                <li>Operating system</li>
                                <li>Date of last update</li>
                                <li>IP address</li>
                                <li>Log file data on Updates page</li>
                            </ul>
                        </li>
                        <li>
                            <b>For Safari on macOS</b>
                            <ul className="list-disc list-inside ml-6">
                                <li>
                                    <b>Crash reports:</b>
                                    <ul className="list-disc list-inside ml-6">
                                        <li>
                                            UUID and crash traces in order to help a user associate
                                            crash data with specific instances of our products
                                        </li>
                                        <li>Instance IDS</li>
                                        <li>Device type</li>
                                        <li>application ID</li>
                                        <li>App and/or extension version</li>
                                        <li>Free space</li>
                                        <li>Free ram</li>
                                        <li>A timestamp of when a crash happened</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Event tracking:</b>
                                    <ul className="list-disc list-inside ml-6">
                                        <li>App and/or extension version</li>
                                        <li>Device type</li>
                                        <li>Which platforms you are using</li>
                                        <li>Location data based on your IP address (country)</li>
                                        <li>Anonymous interaction events (like button clicks)</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Privacy Protector, based on Chromium, provides Chromium-specific
                            functionality which, however, may send data to Google.
                        </li>
                        <li>
                            <b>For Privacy Protector on desktop, when uninstalling:</b>
                            <ul className="list-disc list-inside ml-6">
                                <li>
                                    <b>Automatically:</b>
                                    <ul className="list-disc list-inside ml-6">
                                        <li>Browser language</li>
                                        <li>Extension name and version</li>
                                        <li>Browser type and version</li>
                                        <li>Operating system and version</li>
                                        <li>Number of notification downloads</li>
                                        <li>
                                            Information about whether the local storage of the
                                            extension configuration is corrupted
                                        </li>
                                        <li>Filter subscriptions enabled / disabled</li>
                                        <li>Version parameter of filter lists</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <b>While using our websites</b>
                    <ul className="list-disc list-inside ml-6">
                        <li>
                            <b>Automatically:</b>
                            <ul className="list-disc list-inside ml-6">
                                <li>IP address</li>
                                <li>
                                    Information about the request, including, but not limited to,
                                    domain and path, date and time, request body and query
                                </li>
                                <li>
                                    Information about the response to the request, including, but
                                    not limited to, response code and bytes
                                </li>
                                <li>Aggregated analytics data</li>
                                <li>
                                    Session ID information necessary to test and operate our
                                    websites
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <h3 className="text-xl font-bold mb-2">Purpose of Collection</h3>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>Improvement of Services:</b> We collect data to enhance the
                    performance and user experience of our extension. This includes
                    analyzing how users interact with the extension to identify areas for
                    improvement.
                </li>
                <li>
                    <b>Security:</b> Data is used to prevent security attacks and ensure
                    the safe operation of our services. For example, IP addresses may be
                    temporarily stored to investigate potential security issues.
                </li>
            </ul>

            <h3 className="text-xl font-bold mb-2">
                How and why do we process your data?
            </h3>

            <ul className="list-disc list-inside mb-4">
                <li>
                    In order to inform you about urgent issues in connection with our
                    products.
                </li>
                <li>
                    To improve and to evaluate our products by processing and analyzing
                    subscription downloads, extension updates, emergency downloads,
                    uninstallation information, and issue reports you send to us.
                </li>
                <li>
                    For technical purposes, such as, but not limited to, preventing
                    security attacks, improving our website / products, and ensuring
                    website / product security.
                </li>
                <li>
                    To analyze aggregated website logs, and for non-EU/EEA users analytics
                    data, to improve our website.
                </li>
                <li>For discussing and negotiating a partnership with you.</li>
                <li>To assist you with any issues you may have with our products.</li>
                <li>For receiving donations that you send to us and for fraud detection.</li>
                <li>To promote our products.</li>
                <li>
                    In connection with our social media pages, social networks use the
                    data for market research and advertising purposes.
                </li>
                <li>
                    For evaluating your application for recruitment purposes and offering
                    you jobs.
                </li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">Data Protection</h2>

            <p className="mb-2">
                We are committed to safeguarding your personal information. Any data we
                collect is anonymized where possible, and we do not keep more data than
                necessary. Specifically:
            </p>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>Anonymization:</b> Data such as IP addresses are anonymized to
                    protect your identity.
                </li>
                <li>
                    <b>Limited Retention:</b> Data is retained only for as long as
                    necessary to fulfill the purposes described in this policy.
                </li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">Data Retention</h2>

            <p className="mb-2">
                We retain your data only as long as necessary to fulfill the purposes
                described in this policy. Specifically:
            </p>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>Update and Usage Data:</b> Information related to updates and usage
                    is automatically deleted after 30 days.
                </li>
                <li>
                    <b>Error Reports:</b> Error reports and related data are also deleted
                    after 30 days, ensuring that we do not store unnecessary information.
                </li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">Third-Party Services</h2>

            <p className="mb-2">
                Our extension may interact with third-party services (e.g., browser
                update services) which have their own privacy policies. For example:
            </p>

            <ul className="list-disc list-inside mb-4">
                <li>
                    <b>Browser Updates:</b> Updates for our extension may be handled by
                    services such as the Google Web Store, and the data transmitted during
                    these updates is subject to their privacy policies.
                </li>
                <li>
                    <b>Error Reporting:</b> We may use third-party services like Google
                    Analytics to analyze error reports and improve our services. These
                    services have their own privacy practices, which are separate from
                    ours.
                </li>
            </ul>

            <h2 className="text-2xl font-bold mb-2">Changes to This Policy</h2>

            <p className="mb-2">
                We may update this Privacy Policy from time to time. We will notify you
                of any significant changes by posting the new policy on our website. It
                is your responsibility to review this policy periodically.
            </p>

            <h2 className="text-2xl font-bold mb-2">Contact Us</h2>

            <p className="mb-2">
                If you have any questions about this Privacy Policy, please contact us at{' '}
                <a href="mailto:support_pp@outlook.com" className="text-blue-500">
                    support_pp@outlook.com
                </a>
            </p>

            <p className="mb-2">
                By using the Privacy Protector extension, you agree to the collection
                and use of your information as described in this policy.
            </p>
        </div>
    );
};

export default PrivacyPolicy;